<template>
  <slide-x-left-transition class="row align-center" group tag="div">
    <template v-if="!firmOnly">
      <v-col key="customer.active" cols="12" lg="4" md="6">
        <v-switch v-model="obCustomer.active" :label="$t('active')"></v-switch>
      </v-col>

      <v-col key="customer.endcustomer" cols="12" lg="4" md="6">
        <v-switch
          v-model="endCustomer"
          :disabled="isCustomerProvider || isProvider || obCustomer.tax_exempt"
          :label="$t('end.customer')"
          @change="onChangeEndCustmer"
        ></v-switch>
      </v-col>

      <v-col key="customer.tax_exempt" cols="12" lg="4" md="6">
        <v-switch
          v-model="obCustomer.tax_exempt"
          :disabled="endCustomer"
          :label="$t('tax.exempt')"
          @change="onChangeTaxExempt"
        ></v-switch>
      </v-col>

      <v-col key="customer.type" :lg="inDialog ? 6 : 4" cols="12" md="6">
        <v-btn-toggle v-model="obCustomer.type" color="primary" rounded>
          <v-btn :value="1" small>{{ $t("customer") }}</v-btn>
          <v-btn
            :disabled="endCustomer"
            :value="2"
            small
            v-text="$t('provider')"
          />
          <v-btn :disabled="endCustomer" :value="3" small v-text="$t('both')" />
        </v-btn-toggle>
      </v-col>

      <v-col
        v-if="(isCustomer || isCustomerProvider) && canAccountingCustomer"
        key="customer.customer_account_code"
        :lg="inDialog ? 6 : 4"
        cols="12"
        md="6"
      >
        <account-code-select
          v-model="sAccountSalesCode"
          label="customer.account.code"
          payment-method-code="NINGUNO"
        />
      </v-col>

      <v-col
        v-if="(isProvider || isCustomerProvider) && canAccountingProvider"
        key="customer.provider_account_code"
        :lg="inDialog ? 6 : 4"
        cols="12"
        md="6"
      >
        <account-code-select
          v-model="sAccountPurchaseCode"
          label="provider.account.code"
          payment-method-code="NINGUNO"
        />
      </v-col>

      <v-col
        v-if="!!obCustomer.id"
        key="customer.labels"
        :lg="inDialog ? 6 : 4"
        cols="12"
        md="6"
      >
        <company-labels-dialog :model="obCustomer" field="InfoAdicional" />
      </v-col>
    </template>

    <firm-form
      key="customer.firm"
      v-model="obFirm"
      :end-customer="endCustomer"
      :tax-exempt="obCustomer.tax_exempt"
      all-fields
      location
      code-key="customer"
      @change:doc_type="onChangeDocType"
    />
  </slide-x-left-transition>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CustomerMixin from "@/modules/customers/mixins/CustomerMixin";
import FirmForm from "@/modules/companies/components/FirmForm.vue";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";
import { SlideXLeftTransition } from "vue2-transitions";
import { find, set } from "lodash";
import CompanyLabelsDialog from "@/modules/companies/components/CompanyLabelsDialog.vue";
import { canModuleAccess } from "@/services/moduleAccess";
import { Customer } from "@planetadeleste/vue-mc-gw";
import { LocationModule } from "@/store/location";
import type { CountryData } from "@planetadeleste/vue-mc-location/src/types";

@Component({
  components: {
    CompanyLabelsDialog,
    AccountCodeSelect,
    FirmForm,
    SlideXLeftTransition,
  },
})
export default class CustomersSettings extends Mixins(CustomerMixin) {
  inDialog = false;

  get endCustomer(): boolean {
    return this.getData("end_customer", false);
  }

  set endCustomer(bVal: boolean) {
    this.setData("end_customer", bVal);
  }

  get sAccountSalesCode() {
    return this.getData("account_sales", "");
  }

  set sAccountSalesCode(sValue: string) {
    this.setData("account_sales", sValue);
  }

  get sAccountPurchaseCode() {
    return this.getData("account_purchase", "");
  }

  set sAccountPurchaseCode(sValue: string) {
    this.setData("account_purchase", sValue);
  }

  get isCustomer() {
    return this.obCustomer.type === 1;
  }

  get isCustomerProvider() {
    return this.obCustomer.type === 3;
  }

  get isProvider() {
    return this.obCustomer.type === 2;
  }

  get canAccountingCustomer() {
    return canModuleAccess("accounting.code.customers");
  }

  get canAccountingProvider() {
    return canModuleAccess("accounting.code.providers");
  }

  onChangeEndCustmer(bValue: boolean) {
    // if (bValue) {
    //   set(this.obFirm, "doc_type", "");
    //   set(this.obFirm, "doc_id", "");
    // }
  }

  onChangeTaxExempt(bValue: boolean) {
    if (bValue) {
      this.setData("end_customer", false);

      if (
        this.obFirm &&
        this.obFirm.doc_type &&
        ["ci", "rut"].includes(this.obFirm.doc_type)
      ) {
        set(this.obFirm, "doc_type", "other");
      }
    }
  }

  onChangeDocType(sValue: string) {
    if (!["ci", "rut"].includes(sValue)) {
      return;
    }

    const obCountry = find(
      LocationModule.countries.getModelList() as CountryData[],
      { code: "UY" }
    );

    if (obCountry) {
      set(this.obFirm, "country_id", obCountry.id);

      if (sValue === "ci") {
        set(this.obFirm, "address", "--");
        set(this.obFirm, "dgi_dir_fiscal", "--");
      }
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.inDialog = this.$el ? !!this.$el.closest(".v-dialog") : false;
    });

    if (this.obCustomer instanceof Customer && this.obCustomer.isNew()) {
      this.endCustomer = false;
    }
  }
}
</script>
